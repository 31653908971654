import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/core/services/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadsService {
  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  private baseurl = "/adminapplication/";
  private downloadsUrl = this.apiUrl + this.baseurl + "downloads/";
  constructor(protected httpClient: HttpClient, protected authService: AuthService) {}

  public downloadByCycle(applicationCycleId: number,applicationTypeId : number, applicationStatusId :  number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `${this.apiUrl}/adminapplication/DownloadByCycle?applicationCycleId=${applicationCycleId}&applicationTypeId=${applicationTypeId}&applicationStatusId=${applicationStatusId}`,
      { observe: 'response', responseType: 'blob' }
    );
  }
 
}
