import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService) { }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn) {
      return true;
    }

    this.authService.startAuthentication(state.url);
    console.log('AuthGuard:canActivate');
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isSuperAdmin) {
        return true;
      } else {
        console.log('SuperAdminGuard:canActivate::' + this.authService.isSuperAdmin);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('SuperAdminGuard:canActivate');
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})

export class SecurityAdminGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      //alert("isSecurityAdmin &&&&&&&&"+this.authService.isSecurityAdmin+"^^^^^isApplicationsAdmin^^^^^^"+this.authService.isApplicationsAdmin);
      if (this.authService.isSecurityAdmin || this.authService.isApplicationsAdmin) {
        return true;
      } else {
        console.log('SecurityAdminGuard:canActivate::' + this.authService.isSecurityAdmin);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('SecurityAdminGuard:canActivate::');
    return false;
    //return true;
  }
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationAdminGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isApplicationsAdmin||this.authService.isSecurityAdmin) {
        return true;
      } else {
        console.log('ApplicationAdminGuard:canActivate::' + this.authService.isApplicationsAdmin);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('ApplicationAdminGuard:canActivate');
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ModeratorGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      
      if (this.authService.isModerator || this.authService.isApplicationsAdmin) {
        return true;
      } else {
        console.log('ModeratorGuard:canActivate::' + this.authService.isModerator);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('ModeratorGuard:canActivate::');
    return false;
    //return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReviewGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isReviewer || this.authService.isApplicationsAdmin) {
        return true;
      } else {
        console.log('ReviewGuard:canActivate::' + this.authService.isApplicationsAdmin);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('ReviewGuard:canActivate::');
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class TrackingGuard  {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isTrackingAdmin ||this.authService.isSecurityAdmin) {
        return true;
      } else {
        console.log('TrackingGuard:canActivate::' + this.authService.isTrackingAdmin);
        this.router.navigate(['/unauthorized']);
        return false;
      }
    }

    this.authService.startAuthentication(state.url);
    console.log('TrackingGuard:canActivate');
    return false;
  }
}


@Injectable({
	providedIn: 'root'
  })
  export class ReadOnlyGuard  {
  
	constructor(private authService: AuthService, private router: Router) { }
  
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	  if (this.authService.isLoggedIn) {
		if (this.authService.isReadOnly ||this.authService.isSecurityAdmin) {
		  return true;
		} else {
		  console.log('ReadOnlyGuard:canActivate::' + this.authService.isReadOnly);
		  this.router.navigate(['/unauthorized']);
		  return false;
		}
	  }
  
	  this.authService.startAuthentication(state.url);
	  console.log('ReadOnlyGuard:canActivate');
	  return false;
	}
  }
